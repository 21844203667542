import axios from '@/utilities/axios-global'

const RUN_PRO_MASSUPDATE = (context, massUpdateModel) => {
  return new Promise((resolve, reject) => {
    const url = `${context.rootState.user.baseApiUrl}/reviews/pro/massupdate`
    axios.put(url, massUpdateModel).then(
      response => {
        resolve()
      },
      error => {
        console.log(error)
        reject(error)
      }
    )
  })
}

const POST_VALIDATION = (context, payload) => {
  return new Promise((resolve, reject) => {
    const url = process.env.VUE_APP_API_BASE_URL + '/api/reviews/pro/validaterecords'
    axios.post(url, payload).then(
      response => {
        context.commit('SET_PROLIST_RECORD_VALIDATIONS', response.data)
        resolve()
      },
      error => {
        console.log(error)
        reject(error)
      }
    )
  })
}

const POST_ACCOUNTLOOKUP = (context, accountFormItem) => {
  return new Promise((resolve, reject) => {
    const url = process.env.VUE_APP_API_BASE_URL + '/api/reviews/pro/codinglist/accountlookup'
    axios.post(url, accountFormItem).then(
      response => {
        context.commit('SET_ACCOUNTLOOKUP', response.data)
        resolve()
      },
      error => {
        console.log(error)
        reject(error)
      }
    )
  })
}

const POST_PRO_RECORD = async (context, newReview) => {
  try {
    const userEmail = context.rootState.user.user.email
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/pro?userEmail=${userEmail}`, newReview)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_PRO_RECORD_MULTIPLE = async (context, list) => {
  try {
    await axios.post(`${context.rootState.user.baseApiUrl}/reviews/pro/multiple`, list)
  } catch (e) {
    if (e.response.data.message) {
      throw new Error(e.response.data.message)
    }
    throw new Error('Error Creating Records')
  }
}

const POST_PRO_TEMPLATE_MULTIPLE = async (context, list) => {
  try {
    await axios.post(`${context.rootState.user.baseApiUrl}/reviews/pro/importTemplate`, list)
  } catch (e) {
    if (e.response.data.message) {
      throw new Error(e.response.data.message)
    }
    throw new Error('Error Creating Records')
  }
}

const DELETE_PRO_RECORD = async (context, proRecordId) => {
  try {
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/pro/${proRecordId}`)
    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const RUN_PRO_MASSDELETE = (context, ids) => {
  try {
    const response = axios.put(`${context.rootState.user.baseApiUrl}/reviews/pro/massdelete/`, ids)
    return response
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_PRO_LIST_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/pro/paginated/${payload.projectID}`, payload)
    const wrapper = response.data
    if (context.state.proListSelected.length > 0) {
      wrapper.data = wrapper.data.map(x => {
        x.checked = context.state.proListSelected.some(y => y.proRecordId === x.proRecordId)
        return x
      })
    }
    return wrapper
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_PROLIST_STATS = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/pro/stats/${payload.projectID}`, payload)
    context.commit('SET_STATISTICS', response.data.statistics)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_PROLIST_PROJECT_STATS = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/pro/stats/${payload.projectID}`, payload)
    context.commit('SET_PROJECT_STATISTICS', response.data.statistics)
    return response.data
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export function GET_PROLIST_GLOBALSEARCH(context, payload) {
  return new Promise((resolve, reject) => {
    axios.post(process.env.VUE_APP_API_BASE_URL + `/api/reviews/pro/stats/${payload.projectID}`, payload).then(
      response => {
        resolve(response.data)
      },
      err => {
        console.log(err)
        reject(new Error('Error running global search'))
      }
    )
  })
}

const GET_PRO_LIST_ALLIDS = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/pro/allids/${payload.projectID}`, payload)
    context.commit('SET_PROLIST_ALLIDS', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const GET_PRO_REVIEW = async (context, proRecordId) => {
  try {
    const response = await axios.get(context.rootState.user.baseApiUrl + `/reviews/pro/${proRecordId}`)
    context.commit('SET_PRO_REVIEW', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const PUT_PRO_REVIEW = async (context, payload) => {
  try {
    const response = await axios.put(context.rootState.user.baseApiUrl + `/reviews/pro/${payload.proReview.proRecordId}?changedField=${payload.changedField}`, payload.proReview)
    context.commit('SET_TRUCODE_SUBSET', response.data)
    if (['dateOfService', 'placeOfService', 'overrideDecisionMaking2021', 'patientStatus', 'hpiOverride', 'rosOverride', 'pfshOverride', 'examPresent'].includes(payload.changedField)) {
      const history = response.data.proRecordHistory.find(x => x.codeLevel === response.data.reviewLevel)
      context.commit('SET_HISTORY_CALCULATED_FIELDS', history)
      context.commit('SET_HISTORY_OVERRIDE_FIELDS', response.data)
    }
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const PUT_PRO_DX = async (context, payload) => {
  try {
    context.commit('SET_DX_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/pro/${payload.dxRow.proRecordId}/dx/${payload.dxRow.dxId}?changedField=${payload.changedField}`, payload.dxRow)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_DX_LOADING', false)
  }
}

const POST_PRO_DX = async (context, payload) => {
  try {
    context.commit('SET_DX_LOADING', true)
    const proRecordId = payload.length ? payload[0].proRecordId : payload.proRecordId
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/pro/${proRecordId}/dx`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_DX_LOADING', false)
  }
}

const DELETE_PRO_DX = async (context, payload) => {
  try {
    context.commit('SET_DX_LOADING', true)
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/pro/${payload.proRecordId}/dx/${payload.dxId}`, payload)
    context.commit('DELETE_DX_ROW', payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_DX_LOADING', false)
  }
}

const PUT_PRO_DXS_FOR_RESEQUENCE = async (context, payload) => {
  try {
    context.commit('SET_DX_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/pro/${payload[0].proRecordId}/dx/resequence`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_DX_LOADING', false)
  }
}

const PUT_PRO_CPT = async (context, payload) => {
  try {
    context.commit('SET_CPT_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/pro/${payload.cptRow.proRecordId}/cpt/${payload.cptRow.cptId}?changedField=${payload.changedField}`, payload.cptRow)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_CPT_LOADING', false)
  }
}

const POST_PRO_CPT = async (context, payload) => {
  try {
    context.commit('SET_CPT_LOADING', true)
    const proRecordId = payload.length ? payload[0].proRecordId : payload.proRecordId
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/pro/${proRecordId}/cpt`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_CPT_LOADING', false)
  }
}

const DELETE_PRO_CPT = async (context, payload) => {
  try {
    context.commit('SET_CPT_LOADING', true)
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/pro/${payload.proRecordId}/cpt/${payload.cptId}`, payload)
    context.commit('DELETE_CPT_ROW', payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_CPT_LOADING', false)
  }
}

const PUT_PRO_CPTS_FOR_RESEQUENCE = async (context, payload) => {
  try {
    context.commit('SET_CPT_LOADING', true)
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/pro/${payload[0].proRecordId}/cpt/resequence`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  } finally {
    context.commit('SET_CPT_LOADING', false)
  }
}

const PUT_PRO_MODIFIERS_FOR_RESEQUENCE = async (context, payload) => {
  try {
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/pro/${payload[0].proRecordId}/modifiers/resequence`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const PUT_PRO_HISTORY = async (context, payload) => {
  try {
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/pro/${payload.history.proRecordId}/history/${payload.history.historyId}?changedField=${payload.changedField}`, payload.history)
    context.commit('SET_HISTORY_CALCULATED_FIELDS', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const GET_EXAM_ELEMENTS = async (context, payload) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/reviews/pro/examelements`)
    context.commit('SET_EXAM_ELEMENTS', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const POST_PRORECORD_EXAMDETAILS = async (context, proRecordExamDetails) => {
  try {
    const url = `${context.rootState.user.baseApiUrl}/reviews/pro/${proRecordExamDetails.proRecordId}/examdetails`
    const response = await axios.post(url, proRecordExamDetails)
    context.commit('SET_EXAM_SCORING', response.data)
    context.commit('PUSH_EXAM_DETAILS', proRecordExamDetails)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const DELETE_PRORECORD_EXAMDETAILS = async (context, proRecordExamDetails) => {
  try {
    const url = `${context.rootState.user.baseApiUrl}/reviews/pro/${proRecordExamDetails.proRecordId}/examdetails`
    const response = await axios.delete(url, { data: proRecordExamDetails })
    context.commit('SET_EXAM_SCORING', response.data)
    context.commit('SPLICE_EXAM_DETAILS', proRecordExamDetails)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const PUT_PRO_DECISION_MAKING = async (context, payload) => {
  try {
    const url = `${context.rootState.user.baseApiUrl}/reviews/pro/${payload.decisionMaking.proRecordId}/decisionmaking/${payload.decisionMaking.decisionMakingId}?changedField=${payload.changedField}`
    const response = await axios.put(url, payload.decisionMaking)
    context.commit('SET_DECISION_MAKING_CALCULATED_FIELDS', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const PUT_PRO_MODIFIER = async (context, payload) => {
  try {
    const proRecordId = payload.proRecordId
    const modRow = payload.modRow
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/pro/${proRecordId}/modifiers/${modRow.modifierId}?changedField=${payload.changedField}`, modRow)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const POST_PRO_MODIFIER = async (context, payload) => {
  try {
    const proRecordId = payload.proRecordId
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/pro/${proRecordId}/modifiers`, payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const DELETE_PRO_MODIFIER = async (context, payload) => {
  try {
    const response = await axios.delete(`${context.rootState.user.baseApiUrl}/reviews/pro/${payload.proRecordId}/modifiers/${payload.modifierId}`, payload)
    // context.commit('DELETE_MODIFIER_ROW', payload)
    context.commit('SET_TRUCODE_SUBSET', response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const PUT_FINDING_COMPLIANCE_REASONS = async (context, payload) => {
  try {
    const response = await axios.put(`${context.rootState.user.baseApiUrl}/reviews/pro/${payload.proRecordId}/findings/complianceReasons`, payload.selections)
    // context.commit('DELETE_MODIFIER_ROW', payload)
    // context.commit('SET_TRUCODE_SUBSET', response.data)
    console.log(response.data)
  } catch (err) {
    console.log(err)
    throw new Error(err)
  }
}

const POST_NOTIFY_CODERS = async (context, payload) => {
  try {
    await axios.post(`${context.rootState.user.baseApiUrl}/reviews/pro/notifycoders/`, payload)
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const POST_PRO_RISK_ANALYSIS = async (context, list) => {
  try {
    await axios.post(`${context.rootState.user.baseApiUrl}/reviews/proriskanalysis`, list)
  } catch (e) {
    if (e.response.data.message) {
      throw new Error(e.response.data.message)
    }
    throw new Error('Error Importing Records')
  }
}

const LOAD_DISTRIBUTION_BY_PROVIDER_LIST_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/proriskanalysis/getdistributionbyproviderpaginated?startDate=${payload.dateRange.startDate}&endDate=${payload.dateRange.endDate}`, payload)
    const wrapper = response.data
    return wrapper
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_DISTRIBUTION_BY_SPECIALTY_LIST_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/proriskanalysis/getdistributionbyspecialtypaginated?startDate=${payload.dateRange.startDate}&endDate=${payload.dateRange.endDate}`, payload)
    const wrapper = response.data
    return wrapper
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_OVERALL_DISTRIBUTION = async (context, payload) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/reviews/proriskanalysis/getoveralldistribution?startDate=${payload.dateRange.startDate}&endDate=${payload.dateRange.endDate}`)
    const wrapper = response.data
    return wrapper
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_PROVIDER_RECORDS = async (context, payload) => {
  try {
    const response = await axios.get(`${context.rootState.user.baseApiUrl}/reviews/proriskanalysis/getproviderrecords?startDate=${payload.dateRange.startDate}&endDate=${payload.dateRange.endDate}&providerName=${payload.providerName}`)
    const wrapper = response.data
    return wrapper
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_PRO_RISK_RECORD_LIST_PAGINATED = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/proriskanalysis/getproriskanalysisrecordspaginated?startDate=${payload.dateRange.startDate}&endDate=${payload.dateRange.endDate}`, payload)
    const wrapper = response.data
    return wrapper
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

const LOAD_PRO_RISK_RECORD_RANDOM_LIST = async (context, payload) => {
  try {
    const response = await axios.post(`${context.rootState.user.baseApiUrl}/reviews/proriskanalysis/getproriskanalysisrecordsrandom?startDate=${payload.dateRange.startDate}&endDate=${payload.dateRange.endDate}`, payload)
    const wrapper = response.data
    return wrapper
  } catch (err) {
    console.log(err)
    throw new Error(err.response.data.message)
  }
}

export default {
  GET_PRO_REVIEW,
  PUT_PRO_REVIEW,
  PUT_PRO_DX,
  POST_PRO_DX,
  DELETE_PRO_DX,
  PUT_PRO_DXS_FOR_RESEQUENCE,
  PUT_PRO_CPT,
  POST_PRO_CPT,
  DELETE_PRO_CPT,
  PUT_PRO_CPTS_FOR_RESEQUENCE,
  PUT_PRO_MODIFIERS_FOR_RESEQUENCE,
  RUN_PRO_MASSUPDATE,
  POST_PRO_RECORD,
  POST_ACCOUNTLOOKUP,
  DELETE_PRO_RECORD,
  RUN_PRO_MASSDELETE,
  GET_PROLIST_STATS,
  GET_PROLIST_GLOBALSEARCH,
  GET_PRO_LIST_ALLIDS,
  LOAD_PRO_LIST_PAGINATED,
  POST_VALIDATION,
  PUT_PRO_HISTORY,
  GET_EXAM_ELEMENTS,
  POST_PRORECORD_EXAMDETAILS,
  DELETE_PRORECORD_EXAMDETAILS,
  PUT_PRO_DECISION_MAKING,
  POST_PRO_MODIFIER,
  DELETE_PRO_MODIFIER,
  PUT_PRO_MODIFIER,
  POST_PRO_RECORD_MULTIPLE,
  PUT_FINDING_COMPLIANCE_REASONS,
  POST_NOTIFY_CODERS,
  POST_PRO_TEMPLATE_MULTIPLE,
  GET_PROLIST_PROJECT_STATS,
  POST_PRO_RISK_ANALYSIS,
  LOAD_DISTRIBUTION_BY_PROVIDER_LIST_PAGINATED,
  LOAD_DISTRIBUTION_BY_SPECIALTY_LIST_PAGINATED,
  LOAD_OVERALL_DISTRIBUTION,
  LOAD_PROVIDER_RECORDS,
  LOAD_PRO_RISK_RECORD_LIST_PAGINATED,
  LOAD_PRO_RISK_RECORD_RANDOM_LIST
}
