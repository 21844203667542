import actions from './actions.js'
import mutations from './mutations'
import getters from './getters.js'

const oneHourInMilliseconds = 3600000
const eightHoursInMilliseconds = 28800000
// const fiveminutesInMilliseconds = 300000

const state = {
  dropdowns: {
    reviewLevels: { list: [] },
    consultants: { list: [] },
    oneToFour: { list: [] },
    pointOfOrigin: { list: [] },
    dcSummaryAvailableAtCoding: { list: [] },
    dcSummaryAvailableAtReview: { list: [] },
    patientStatus: { list: [] },
    drgSystems: { list: [] },
    otherDrgSystems: { list: [] },
    payors: { list: [] },
    patientSex: { list: [] },
    inpatientReasonsForChange: { list: [] },
    reasonsForChangeOtherDrg: { list: [] },
    groupers: { list: [] },
    grouperVersions: { list: [] },
    pricers: { list: [] },
    ipPatientTypes: { list: [] },
    reviewStatus: { list: [] },
    clients: { list: [] },
    RebuttalStatus: { list: [] },
    opPatientTypes: { list: [] },
    outpatientReasonsForChange: { list: [] },
    codeTypes: { list: [] },
    modifiers: { list: [] },
    YesNoNAUnknown: { list: [] },
    outpatientGroupers: { list: [] },
    ascPaymentIndicators: { list: [] },
    proReasonsForChange: { list: [] },
    placeOfService: { list: [] },
    ProPatientStatus: { list: [] },
    proExam_ExamTypes: { list: [] },
    proExam_ExamTypesSystemAreasCrossover: { list: [] },
    proExam_SystemAreas: { list: [] },
    ProRiskDefinitionsPP: { list: [] },
    ProRiskDefinitionsDPO: { list: [] },
    ProRiskDefinitionsMOS: { list: [] },
    ProRiskDefinitionsPP2021: { list: [] },
    ProRiskDefinitionsMOS2021: { list: [] },
    ApcPaymentRates: { list: [] },
    inpatientReasonsForChangeHeader: { list: [] },
    inpatientReasonsForChangeDx: { list: [] },
    inpatientReasonsForChangePx: { list: [] },
    outpatientReasonsForChangeHeader: { list: [] },
    outpatientReasonsForChangeDx: { list: [] },
    outpatientReasonsForChangePx: { list: [] },
    outpatientReasonsForChangeCpt: { list: [] },
    outpatientReasonsForChangeApc: { list: [] },
    allReasonsForChange: { list: [] },
    proReasonsForChangeHeader: { list: [] },
    proReasonsForChangeDx: { list: [] },
    proReasonsForChangeCpt: { list: [] },
    ambulatoryPatientTypes: { list: [] },
    outpatientPatientTypes: { list: [] },
    projectTypes: { list: [] },
    allProjectTypes: { list: [] },
    projectStatus: { list: [] },
    projectIdentifiers: { list: [] },
    recordTags: { list: [] },
    projectProcesses: { list: [] },
    clientChecklistsPro: { list: [] },
    clientChecklistsIP: { list: [] },
    clientChecklistsOP: { list: [] },
    clientChecklistsAMB: { list: [] },
    proRiskEMCategories: { list: [] },
    proRiskProviderSpecialties: { list: [] }
  },
  dropdownsDetails: [
    { name: 'example', refreshInterval: oneHourInMilliseconds },
    { name: 'states', refreshInterval: eightHoursInMilliseconds }
  ]
}

const module = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default module
