import { mapState } from 'vuex'

// see dbo.Roles table
const userAdmin = 1
const systemManagement = 2
const inpatientReview = 3
const outpatientReview = 4
const providerReview = 5
const coder = 6
const betaFeatures = 7
const readOnly = 8
const ambulatoryReviewer = 9
const projectManagement = 10
const auditorMetrics = 11

const HiaAuthorization = {
  methods: {
    getUserRoles: function () {
      const tenant = this.user.userTenants.find(x => x.tenantGuid === this.currentTenantGuid)
      return tenant.roles
    },
    ITUser: function () {
      return ['auth0|623dde7c4d8440006fc8d96d', 'auth0|623de5b280df5f00680e95d3', 'auth0|623e0731efb2a8006977cd8e'].includes(this.user.auth0Id)
    },
    userAdmin: function () {
      return this.userInGroup(userAdmin)
    },
    systemManagement: function () {
      return this.userInGroup(systemManagement)
    },
    inpatientReviewer: function () {
      return this.userInGroup(inpatientReview)
    },
    outpatientReviewer: function () {
      return this.userInGroup(outpatientReview)
    },
    providerReviewer: function () {
      return this.userInGroup(providerReview)
    },
    ambulatoryReviewer: function () {
      return this.userInGroup(ambulatoryReviewer)
    },
    coder: function () {
      return this.userInGroup(coder)
    },
    projectManagement: function () {
      return this.userInGroup(projectManagement)
    },
    betaFeatures: function () {
      return this.userInGroup(betaFeatures)
    },
    readOnly: function () {
      return this.userInGroup(readOnly)
    },
    isTrucodeEnabled: function () {
      const tenant = this.user.userTenants.find(x => x.tenantGuid === this.currentTenantGuid)
      return tenant.trucodeEnabled
    },
    isCoderEnhancedEnabled: function () {
      return this.user.isCoderEnhancedEnabled
    },
    userInGroup(group) {
      if (this.getUserRoles().some(x => x.roleId === group)) {
        return true
      }

      // if (this.documentManagerAdmin() || this.ITUser()) {
      //   return true
      // }

      return false
    },
    auditorMetrics: function () {
      return this.userInGroup(auditorMetrics)
    }
    // isUserAdminClient(client) {
    //   if (this.getUserRoles().some(x => x.RoleId === userAdmin[1])) {
    //     return true
    //   }
    // }
  },
  computed: {
    ...mapState('user/', ['user', 'currentTenantGuid'])
  }
}

export default HiaAuthorization
